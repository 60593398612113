<template>
  <v-container>
      <v-row>
          <v-col cols="12">
              <div class="text-h4 mb-2">Ugyldigt token</div>
              <div>Du kan desværre ikke komme videre.</div>
              <div>Mener du dette er en fejl, så kontakt os på <a href="mailto:info@carity.dk">info@carity.dk</a></div>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>