<template>
  <v-app>
    <v-main>
      <loading-token v-if="!tokenLoaded" />
      <invalid-token v-else-if="!tokenValid" />
      <select-collections :token="token" v-else />
    </v-main>
  </v-app>
</template>

<script>
import InvalidToken from './components/InvalidToken.vue';
import LoadingToken from './components/LoadingToken.vue';
import SelectCollections from './components/SelectCollections';

export default {
  name: 'App',

  components: {
    SelectCollections,
    LoadingToken,
    InvalidToken,
  },

  data: () => ({
    tokenLoaded: false,
    tokenValid: false,
    token: ""
  }),

  watch: {
    token: function(newToken, oldToken) {
      if (newToken != oldToken) {
        this.validateToken()
      }
    }
  },

  methods: {
    validateToken() {
      this.axios.get(`https://europe-west1-carity-cube.cloudfunctions.net/themes/cubetoken/${this.token}?plus=true`).then((response) => {
            if (response.status == 200) {
              this.tokenValid = true
            } else {
              this.tokenValid = false
            }
        }).catch(() => {
            this.tokenValid = false
        })
    },
    setToken(token) {
      console.log("Setting token: ", token)
      this.token = token
      if (token) {
        this.tokenLoaded = true
        this.tokenValid = true
        const cookie = `token=${token};expires=${new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 7)).toUTCString()}`
        document.cookie = cookie
      }
    },
    fetchTokenFromURL() {
      const searchParams = new URLSearchParams(window.location.search)
      const token = searchParams.get("token")
      if (!token) return ""
      console.log("found token in URL: " + token)
      return token
    },
    fetchTokenFromCookies() {
      const cookie = document.cookie
      if (cookie) {
        console.log(cookie)
        const tokenParts = cookie.split("=")
        if (tokenParts.length < 2) return ""
        if (tokenParts[0].trim().toLowerCase() !== "token") return ""
        const token = tokenParts[1].trim()
        if (!token) return ""
        console.log("found token in cookies: " + token)
        return token
      }
      return ""
    }
  },

  created() {
    const urlToken = this.fetchTokenFromURL()
    if (urlToken) return this.setToken(urlToken)
    const cookieToken = this.fetchTokenFromCookies()
    if (cookieToken) return this.setToken(cookieToken)
    this.tokenLoaded = true
  }
};
</script>
