<template>
  <v-container>
      <v-row>
          <v-col cols="12">
              <div class="text-h4 mb-2">Vælg indhold til din MemoryCube</div>
              <div>Indlæser...</div>
              <v-progress-linear
                indeterminate
                color="primary"
                style="max-width: 500px;"
              ></v-progress-linear>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>