<template>
    <v-window v-model="step" style="margin-bottom: 144px;">
        <v-window-item class="content-window" :value="1">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="text-h4 mb-2">Vælg indhold til din MemoryCube</div>
                        <div>MemoryCube har 4 indholdssider. Du skal her vælge indholdet på hver af de 4 sider.</div>
                        <div>Du kan vælge blandt vores samlinger, eller vælge, at en eller flere sider skal have dit eget indhold.</div>
                    </v-col>
                </v-row>
                <v-row v-if="loadingCollections">
                    <v-col cols="12">
                        <div>Indlæser...</div>
                        <v-progress-linear
                            indeterminate
                            color="primary"
                            style="max-width: 500px;"
                        ></v-progress-linear>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12" class="pb-0">
                        <v-card flat>
                            <v-divider class="mb-3"></v-divider>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="6" offset="3" offset-md="0" md="4" lg="3" offset-sm="3" order-md="2">
                                        <v-img :src="require('@/assets/smartphone.png')"></v-img>
                                    </v-col>
                                    <v-col cols="12" md="8" lg="9" order-md="1" class="d-flex flex-column justify-space-between">
                                        <div>
                                            <div class="text-h5">Side med eget indhold</div>
                                            <div>- til hvis du vil have din egen side at lægge billeder ind på med vores app. Det kan være fx være nyheder fra plejehjemmet. Du kan selv vælge titel og uploade billede, der skal repræsentere indholdet.</div>
                                        </div>
                                        <div>
                                            <v-slide-x-transition hide-on-leave>
                                                <v-btn v-show="custom < 1" class="mt-2" color="primary" @click="custom = 1">
                                                    <v-icon left>mdi-plus-circle</v-icon>
                                                    Tilvælg
                                                </v-btn>
                                            </v-slide-x-transition>
                                            <v-slide-x-reverse-transition hide-on-leave>
                                                <div v-show="custom > 0" class="mt-2">
                                                    <v-btn color="primary" icon @click="custom--"><v-icon>mdi-minus-circle</v-icon></v-btn>
                                                    <span v-if="custom == 1">1 side</span>
                                                    <span v-else>{{ custom }} sider</span>
                                                    <v-btn color="primary" icon @click="custom++"><v-icon>mdi-plus-circle</v-icon></v-btn>
                                                </div>
                                            </v-slide-x-reverse-transition>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="12" v-for="col in collections" :key="col.id" class="pb-0">
                        <v-card flat>
                            <v-divider class="mb-3"></v-divider>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="6" offset="3" offset-md="0" md="4" lg="3" offset-sm="3" order-md="2">
                                        <v-img :src="col.previewImage" style="cursor: pointer;" @click="showVideo(col)">
                                            <v-icon class="white--text fill-height" style="width: 100%" size="96">mdi-play-circle-outline</v-icon>
                                        </v-img>
                                    </v-col>
                                    <v-col cols="12" md="8" lg="9" order-md="1" class="d-flex flex-column justify-space-between">
                                        <div>
                                            <div class="text-h5" v-text="col.name"></div>
                                            <div v-text="col.description"></div>
                                        </div>
                                        <div>
                                            <v-slide-x-transition hide-on-leave>
                                                <v-btn v-show="!chosen.includes(col)" class="mt-2" color="primary" @click="choose(col)">
                                                    <v-icon left>mdi-plus-circle</v-icon>
                                                    Tilvælg
                                                </v-btn>
                                            </v-slide-x-transition>
                                            <v-slide-x-reverse-transition hide-on-leave>
                                                <v-btn v-show="chosen.includes(col)" class="mt-2" color="primary" outlined @click="unChoose(col)">
                                                    <v-icon left>mdi-check-circle-outline</v-icon>
                                                    Valgt
                                                </v-btn>
                                            </v-slide-x-reverse-transition>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <v-app-bar bottom fixed prominent
                    elevation="4" class="pt-2">
                    <v-spacer></v-spacer>
                    <div v-if="selected < 4">Du har valgt indhold til {{ selected }} ud af 4 sider</div>
                    <div v-else>Du har valgt indhold til alle 4 sider</div>
                        <v-btn :disabled="selected !== 4" class="ml-4" color="primary" @click="next">
                            Videre
                            <v-icon right>mdi-arrow-right-circle</v-icon>
                        </v-btn>
                    <v-spacer></v-spacer>
                </v-app-bar>
            </v-container>
        </v-window-item>
        <v-window-item class="content-window" :value="2">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-btn class="mb-4" color="primary" @click="step = 1">
                            <v-icon left>mdi-arrow-left-circle</v-icon>
                            Tilbage til valg af indhold
                        </v-btn>
                        <div class="text-h4 mb-2">Vælg sidebilleder til MemoryCube</div>
                        <div>Hver af MemoryCubes 4 indholdssider har et billede, der er trykt fysisk på den pågældende side af terningen.</div>
                        <div>Disse billeder skal altså repræsentere indholdet på den pågældende side.</div>
                        <div>Ligeledes har hver side også et navn som vises når en præsentation startes.</div>
                        <div>Vi har på baggrund af dine valgte samlinger forhåndsudfyldt nogle billeder og navne, men du kan vælge frit.</div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" v-for="(side, idx) in sides" :key="idx" :order="sides.length - idx">
                        <v-card flat>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="5" md="4" lg="2">
                                        <v-responsive :aspect-ratio="1" class="cube-image">
                                            <v-img style="max-height: 89%" v-if="side.image" :src="side.image"></v-img>
                                            <v-img style="max-height: 89%" v-else-if="side.collection" :src="side.collection.cubeImage"></v-img>
                                            <v-img style="max-height: 89%" v-else :src="require('@/assets/transparent-hack.png')"></v-img>
                                            <div class="image-action">
                                                <v-btn v-if="side.image && side.collection" rounded dark color="red darken-2" @click="removeFile(idx)">
                                                    <v-icon left>mdi-image-remove</v-icon>
                                                    Nulstil
                                                </v-btn>
                                                <v-btn v-else-if="side.collection || side.image" rounded color="primary" @click="clickFile(idx)">
                                                    <v-icon left>mdi-image-refresh</v-icon>
                                                    Erstat
                                                </v-btn>
                                                <v-btn v-else rounded color="primary" @click="clickFile(idx)">
                                                    <v-icon left>mdi-image-plus</v-icon>
                                                    Tilføj
                                                </v-btn>
                                            </div>
                                            <input type="file" :ref="'file-' + idx" @change.prevent="loadImage(idx, $event)" accept="image/png, image/jpeg" hidden />
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="7" md="8" lg="10" class="d-flex flex-column justify-space-between">
                                        <div>
                                            <v-text-field v-model="side.name" class="mb-2" outlined label="Sidens navn" placeholder="F.eks. 'Nyt fra Plejehjemmet'" hide-details></v-text-field>
                                            <div v-if="side.collection">
                                                <div class="text-caption">Tilknyttet samling:</div>
                                                <div class="text-subtitle-2" v-text="side.collection.name"></div>
                                            </div>
                                            <div v-else>
                                                <div class="text-caption">Eget indhold</div>
                                            </div>
                                        </div>
                                        <div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <v-app-bar bottom fixed prominent
                    elevation="4" class="pt-2">
                    <v-spacer></v-spacer>
                    <div v-if="configured < 4">Du har valgt titel og billede til {{ configured }} ud af 4 sider</div>
                    <div v-else>Du har valgt titel og billede til alle 4 sider</div>
                        <v-btn :disabled="configured !== 4" :loading="orderProgress > -1" class="ml-4" color="primary" @click="order">
                            Bestil
                            <v-icon right>mdi-arrow-right-circle</v-icon>
                        </v-btn>
                    <v-spacer></v-spacer>
                </v-app-bar>
            </v-container>
        </v-window-item>
        <v-window-item class="content-window" :value="3">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="text-h4 mb-2">Tak!</div>
                        <div>Vi har nu alt hvad vi skal bruge for at producere din MemoryCube.</div>
                        <div>Når vi begynder at producere den får du nærmere information om levering.</div>
                        <div>Hvis du har valgt sider med eget indhold, så hold øje med din indbakke - vi sender en invitation til vores app.</div>
                    </v-col>
                </v-row>
            </v-container>
        </v-window-item>
        <v-dialog v-model="previewVideoDialog">
            <v-card v-if="previewVideoTitle && previewVideo">
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="hideVideo">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Uddrag af {{ previewVideoTitle }}</v-toolbar-title>
                </v-toolbar>
                <video class="preview-video" :src="previewVideo" autoplay controls></video>
            </v-card>
        </v-dialog>
        <v-dialog v-model="cropImageDialog">
            <v-card v-if="cropImageSrc">
                <v-toolbar>
                    <v-btn icon @click="cancelCrop">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Beskær billede</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="saveCrop">
                        <v-icon left>mdi-content-save</v-icon>
                        Gem
                    </v-btn>
                </v-toolbar>
                <v-responsive class="cropper-container" :aspect-ratio="67/74">
                    <cropper class="cropper fill-height" ref="cropper" :src="cropImageSrc" :stencil-props="{aspectRatio: 67/74}"></cropper>
                </v-responsive>
            </v-card>
        </v-dialog>
    </v-window>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default {
    components: {
        Cropper,
    },
    props: {
        token: String,
    },
    data: () => ({
        step: 1,
        loadingCollections: false,
        collections: [],
        chosen: [],
        custom: 0,
        sides: Array(4).fill({name: '', collection: null}),
        previewVideo: "",
        previewVideoTitle: "",
        previewVideoDialog: false,
        cropImageSrc: null,
        cropImageType: "",
        cropImageSide: null,
        cropImageDialog: false,
        orderProgress: -1,
    }),
    computed: {
        selected: function () {
            return this.custom + this.chosen.length
        },
        configured: function () {
            return this.sides.filter((s) => s.name.length > 0 && (s.collection || s.image)).length
        }
    },
    created() {
        this.loadingCollections = true
        this.axios.get("https://europe-west1-carity-cube.cloudfunctions.net/themes/collections/json").then((response) => {
            this.collections = [...Object.values(response.data).flat()]
        }).finally(() => {
            this.loadingCollections = false
        })
    },
    methods: {
        async order() {
            this.orderProgress = 0
            const withImages = this.sides.filter((s) => s.image)
            const progressIncrease = 1 / (withImages.length + 1)
            for (const si of withImages) {
                const imagePath = await this.uploadImage(si)
                si.imagePath = imagePath
                console.log("uploaded", si)
                this.orderProgress += progressIncrease
            }
            await this.axios.post(
                `https://europe-west1-carity-cube.cloudfunctions.net/themes/finalizePlus`, {
                    token: this.token,
                    themes: this.sides.map((s) => {
                        const tObj = {name: s.name}
                        if (s.imagePath) tObj['image'] = s.imagePath
                        if (s.collection) {
                            tObj['collections'] = [s.collection.id]
                        } else {
                            tObj['collections'] = []
                        }
                        return tObj
                    })
                }).then(() => {
                    this.orderProgress = 1
                    this.step = 3
                    this.chosen = []
                    this.custom = 0
                    this.sides = Array(4).fill({name: '', collection: null})
                    document.cookie = ""
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
        },
        async uploadImage(side) {
            const idx = this.sides.indexOf(side)
            const blob = await fetch(side.image).then((r) => r.blob())
            const formData = new FormData()
            formData.append('file', blob)
            return this.axios.post(
                `https://europe-west1-carity-cube.cloudfunctions.net/themes/finalizePlus/uploadCropped/${this.token}/${idx}/`,
                formData, 
                {headers: {'Content-Type': 'multipart/form-data'}}).then((response) => response.data.path)
        },
        next() {
            let newSides = []
            for (const [i, s] of this.sides.entries()) {
                const nS = {name: s.name, collection: s.collection}
                const isColName = (nS.name != '' && this.chosen.some((c) => nS.name == c.name))
                if (i < this.chosen.length) {
                    nS.collection = this.chosen[i]
                    if (nS.name == '' || isColName) nS.name = this.chosen[i].name
                } else {
                    if (isColName) nS.name = ''
                    nS.collection = null
                }
                newSides.push(nS)
            }
            this.sides = newSides
            this.step = 2
        },
        choose(col) {
            if (this.chosen.includes(col)) return
            this.chosen.push(col)
        },
        unChoose(col) {
            if (!this.chosen.includes(col)) return
            this.chosen = this.chosen.filter(i => i !== col)
        },
        showVideo(col) {
            this.previewVideo = col.previewVideo
            this.previewVideoTitle = col.name
            this.previewVideoDialog = true
        },
        hideVideo() {
            this.previewVideoDialog = false
            this.previewVideoTitle = ""
            this.previewVideo = ""
        },
        saveCrop() {
            const { canvas } = this.$refs.cropper.getResult()
            canvas.toBlob((blob) => {
                const url = URL.createObjectURL(blob)
                const s = this.sides[this.cropImageSide]
                s.image = url
                this.$set(this.sides, this.cropImageSide, s)
                this.cancelCrop()
            }, "image/png")
        },
        cancelCrop() {
            this.cropImageDialog = false
            this.cropImageSrc = null
            this.cropImageSide = null
            this.cropImageType = ""
        },
        clickFile(idx) {
            const r = this.$refs['file-' + idx][0]
            r.click()
        },
        removeFile(idx) {
            const s = this.sides[idx]
            delete s.image
            this.$set(this.sides, idx, s)
        },
        loadImage(side, event) {
            const { files } = event.target
            if (files && files[0]) {
                const blob = URL.createObjectURL(files[0])
                this.cropImageSrc = blob
                this.cropImageType = files[0].type
                this.cropImageSide = side
                this.cropImageDialog = true
            }
        }
    }
}
</script>

<style>
.content-window {
    min-height: 100vh;
}
.content-window > .container {
    max-width: 1264px;
}
.cube-image {
  padding-top: 10%;
  padding-left: 9%;
  padding-right: 10%;
  padding-bottom: 8%;
  background: url("../assets/cube-empty.png") no-repeat;
  background-size: contain;
}
.image-action {
    position: absolute;
    bottom: 12%;
    right: 8%;
}
.preview-video {
    width: 100%;
    height: 100%;
    margin-bottom: -8px;
}
.cropper-container {
    max-height: 75vh;
}
</style>